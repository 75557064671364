<template>
  <v-card class="rounded-xl moholand-shadow">
    <v-card-text>
      <v-row>
        <v-col
          :cols="inputsGrid.cols"
          :lg="
            inputsGrid.lg || inputsGrid.md || inputsGrid.sm || inputsGrid.cols
          "
          :md="inputsGrid.md || inputsGrid.sm || inputsGrid.cols"
          :sm="inputsGrid.sm || inputsGrid.cols"
        >
          <h2>نظرات:</h2>
          <v-form ref="form" class="mt-6">
            <v-row>
              <v-col cols="6">
                <div>امتیاز شما</div>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-rating
                  v-model="rate"
                  color="amber"
                  dense
                  size="20"
                  length="5"
                  :rules="[rules.required]"
                ></v-rating>
                <small class="font-weight-bold">({{ rate }} از 5)</small>
              </v-col>
            </v-row>
            <div class="d-flex align-center my-4" v-if="replay">
              <div>در پاسخ به:</div>
              <div class="rounded-lg grey lighten-2 pa-2 mx-1" style="flex: 1">
                <div class="one-line-text">{{ replay.desc }}</div>
                <v-divider class="my-1" />
                <div class="d-flex justify-space-between">
                  <small>
                    توسط:
                    {{ replay.name || "" }}
                  </small>
                  <small class="">
                    {{ replay.created_at | moment("jYYYY/jMM/jDD") }}
                  </small>
                </div>
              </div>
              <v-btn icon small @click="replay = null">
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  background-color="grey lighten-2"
                  hide-details="auto"
                  label="نام"
                  v-model="name"
                  solo
                  flat
                  :rules="[rules.required]"
                  class="rounded-lg"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  background-color="grey lighten-2"
                  hide-details="auto"
                  label="ایمیل"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  class="rounded-lg"
                  solo
                  flat
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              background-color="grey lighten-2"
              hide-details="auto"
              v-model="desc"
              :rules="[rules.required]"
              label="هر نظری که دوست داری بنویس"
              class="mt-6 rounded-lg"
              solo
              flat
            />
            <v-btn
              block
              dark
              large
              color="success"
              elevation="0"
              class="rounded-lg mt-6"
              @click="submit"
              :loading="loading"
            >
              نظرتو به اشتراک بذار
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          :cols="commentsGrid.cols"
          :lg="
            commentsGrid.lg ||
              commentsGrid.md ||
              commentsGrid.sm ||
              commentsGrid.cols
          "
          :md="commentsGrid.md || commentsGrid.sm || commentsGrid.cols"
          :sm="commentsGrid.sm || commentsGrid.cols"
          :class="
            comments.length
              ? 'ma-0 mb-5'
              : 'ma-0 mb-5 d-flex justify-center align-center'
          "
        >
          <div class="comments-container">
            <h4
              v-if="comments.length === 0"
              class="grey--text text-center d-flex justify-center align-center"
            >
              هیچ نظری وجود ندارد
            </h4>
            <div
              class="grey lighten-2 rounded-lg pa-3 mt-6"
              v-for="(item, i) in comments"
              :key="'comments' + i"
            >
              <v-alert
                v-if="item.parent"
                border="left"
                color="grey darken-1"
                elevation="0"
                class="white--text"
                dense
              >
                <div class="one-line-text text-justify">
                  {{ item.parent.desc }}
                </div>
                <v-divider class="my-1" dark />
                <div class="d-flex justify-space-between">
                  <small>
                    توسط:
                    {{ item.parent.name || "" }}
                  </small>
                  <small class="">
                    {{ item.parent.created_at | moment("jYYYY/jMM/jDD") }}
                  </small>
                </div>
              </v-alert>
              <div class="d-flex justify-space-between">
                <div class="text-justify">{{ item.desc }}</div>
                <div
                  class="d-flex flex-column align-end justify-space-between mr-4"
                >
                  <v-btn
                    text
                    x-small
                    color="grey darken-1"
                    @click="replayComment(item)"
                  >
                    <small>پاسخ</small>
                  </v-btn>
                  <v-rating
                    color="amber"
                    dense
                    size="15"
                    length="5"
                    readonly
                    :value="item.rate"
                  />
                </div>
              </div>
              <v-divider class="my-2" />
              <div class="d-flex justify-space-between">
                <small>
                  توسط:
                  {{ item.name || "" }}
                </small>
                <small class="">
                  {{ item.created_at | moment("jYYYY/jMM/jDD") }}
                </small>
              </div>
            </div>
            <v-btn
              v-if="page < last_page"
              block
              dark
              link
              icon
              color="grey"
              elevation="0"
              class="rounded-lg mt-6"
              @click="onMoreClicked"
            >
              نظرات بیشتر...
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MySnackbar from "../../components/MySnackbar";
import MyAxios from "../../constants/MyAxios";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import { Constants } from "../../constants/GlobalConstants";

export default {
  components: {
    MySnackbar,
  },
  props: {
    inputsGrid: {
      type: Object,
      default: () => ({ cols: 12, sm: 6, md: 12 }),
    },
    commentsGrid: {
      type: Object,
      default: () => ({ cols: 12, sm: 6, md: 12 }),
    },
    model_id: {
      type: String,
      required: true,
    },
    // model_slug: {
    //   type: String,
    //   required: true,
    // },
    comment_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: Constants.rules,
      loading: false,
      name: null,
      email: null,
      desc: null,
      replay: null,
      rate: 0,
      comments: [],
      page: 1,
      last_page: 1,
      model_slug: this.$route.params.slug || this.$route.params.id || "",
    };
  },
  watch: {
    "$route.params.slug"(newSlug, oldSlug) {
      this.model_slug = newSlug;
      this.name = null;
      this.email = null;
      this.desc = null;
      this.replay = null;
      this.rate = 0;
      this.comments = [];
      this.page = 1;
      this.last_page = 1;
      this.GetData();
    },
  },
  created() {
    this.GetData();
  },
  methods: {
    onMoreClicked() {
      this.page += 1;
      this.GetData();
    },
    replayComment(item) {
      console.log(item);
      this.replay = item;
    },
    GetData: function() {
      this.loading = true;
      MyAxios.get(
        "reviews/" + this.comment_type + "/index/" + this.model_slug,
        {
          params: {
            page: this.page,
            with: ["parent", "user"],
          },
        }
      )
        .then((response) => {
          this.comments = this.comments.concat(response.data.data);
          this.loading = false;
          this.last_page = response.data.last_page;
          console.log("response.data[0]", response.data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    reset() {
      this.name = null;
      this.email = null;
      this.desc = null;
      this.replay = null;
      this.rate = 0;
    },
    submit() {
      console.log(this.replay);
      if (this.rate === 0) {
        this.$root.$emit("toast", {
          text: "امتیاز خود را با لمس ستاره ها مشخص کنید!",
        });
      } else if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          name: this.name,
          email: this.email,
          desc: this.desc,
          rate: this.rate,
          user_id: this.$store.state?.userData?.id || undefined,
          parent_id: this.replay?.id || undefined,
        };

        let formdata = formPostPrepare(data);

        MyAxios.post(
          "reviews/" + this.comment_type + "s/create/" + this.model_slug,
          formdata
        )
          .then((response) => {
            this.comments = [];
            this.page = 1;
            this.loading = false;
            this.$root.$emit("toast", {
              text:
                "نظر شما با موفقیت ثبت شد و پس از تایید در سایت نمایش داده میشود.",
              type: "success",
            });
            this.GetData();
            this.reset();
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "اطلاعات را به طور کامل وارد نمایید.",
              type: "error",
            });
            console.log(error.response || error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "تمام فیلد ها را به درستی پر نمایید.",
        });
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 959px) and (min-width: 600px) {
  .comments-container {
    width: 100%;
    max-height: 450px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 960px) {
  .comments-container {
    width: 100%;
    max-height: 450px;
    overflow-y: auto;
  }
}
@media screen and (max-width: 599px) {
  .comments-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
